import React from "react";
import { Link } from "gatsby";
import { FaRegCalendarAlt,FaFolder,FaTag } from 'react-icons/fa'
import moment from 'moment';
import { GatsbyImage, getImage, IGatsbyImageData, StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { kebabCase } from 'lodash/string';

type Props = {
  data: any
}

const Wrapper = styled.div`
  article{ display:flex; flex-direction:row-reverse; padding:15px 8px; border-bottom: 1px solid #ccc;}
  article .main{ display:flex; flex-direction:column; flex-grow:2; padding-left:16px; justify-content:space-around; }
  article .main > a{ text-decoration:none;}
  article .main > a > h2{ font-size:17px;}
  /* article .main .post-meta{ margin-top:-25px; } */
  article .main .post-meta .date{ line-height:150%; font-size: 12px;}
  article .main .post-meta .tag{ line-height:150%; font-size: 12px; }
  article .main .post-meta a{ text-decoration: none; color:#666;  }
  article .sub{ width:65px; text-align:center; flex-shrink:0; }
  article .sub .status.open{ margin-top:7px; font-size:10px; padding:1px ; font-weight:bold; border-radius:5px; background-color: #ddd; color: #666; }
  article .sub .status.close{ margin-top:7px; font-size:10px; padding:1px ; font-weight:bold; border-radius:5px; background-color: #00bb03; color: #fff; }
  .gatsby-image-wrapper [data-main-image]{ margin-top:5px; padding:5px !important; }
`
export default function IssueItem(props: Props){
  const {icon,title,date,tags,open} = props.data.frontmatter;
  const slug = props.data.parent.name;
  return (
    <Wrapper>
      <article>
        <div className="main">
          <Link to={`/issue/${slug}`}>
            <h2>{title}</h2>
          </Link>
          <div className='post-meta'>
            <div className='date'>
              <FaRegCalendarAlt /> {moment(date).format(`YYYY年M月D日 H:mm`)} 
            </div>
            <div className='tag'>
              <FaTag /> 
              { tags.map((tag:string) => (
                <Link key={tag} to={`/tag/${kebabCase(tag)}`}><span> { tag } </span></Link>
              ))}
            </div>
          </div>
        </div>
        <div className="sub">
          {open === false && (
            <div className="status open">未解決</div>
          )}
          {open === true && (
            <div className="status close">解決済</div>
          )}
          <div className="icon">
            {getImage(icon) ? (
              <GatsbyImage image={getImage(icon) as IGatsbyImageData} alt={title} />
            ) : (
              <StaticImage alt={title} src="../../../images/no-image.png" />
            )}
          </div>
        </div>
      </article>
    </Wrapper>
  )
}