import React from 'react';
import { Link } from 'gatsby';
import { Row, Col, Card } from 'react-bootstrap';
import { StaticImage, GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { FaRegCalendarAlt,FaFolder,FaTag } from 'react-icons/fa'
import moment from 'moment';
import IssueItem from '../parts/IssueItem';

const Wrapper = styled.div`
  /* .qa-list{ margin-bottom: 30px; }
  .qa-list h2{ font-size: 20px; font-weight: bold;}
  .qa-list h2 a{ text-decoration:none;}
  .card-title { font-size: 15px; font-weight: bold; margin-bottom: 0; text-align: left; }
  .date,.category,.tag{ font-size:14px; color:#333; }
  .category:before{ content: " "; }
  .card-body a{ text-decoration: none; }
  .post-meta{ line-height: 100%; color:#333; }
  .post-meta a{ text-decoration: none; color:#333; }
  .post-meta svg{ transform: translate(1px,-2px); }
  .gatsby-image-wrapper [data-main-image]{ padding:25px; } */
`;
export default function IssueCardTemplate(props: any) {
  const { data } = props;
  return (
    <Wrapper>
      <div className='qa-list'>
      {data.nodes.map((node: any) => (
        <IssueItem data={node} key={node.id}></IssueItem>
      ))}
      </div>
    </Wrapper>
  );
}
          