import * as React from "react"
import { graphql } from 'gatsby';
import 'bootstrap/dist/css/bootstrap.min.css';
import PostCardGroup from "../components/templates/PostCardGroup";
import { Helmet } from "react-helmet";
import TopLayout from "../components/layout/TopLayout";
import IssueCardTemplate from "../components/templates/IssueCardTemplate";
import { IndexQuery } from "../../graphql-types";

type Props = {
  data : IndexQuery
}

const IndexPage = (props:Props) => {
  const { title:siteTitle,description:siteDesc,siteUrl } = props.data.site.siteMetadata;
  return (
    <>
      <Helmet>
        <html lang="ja" />
        <title>{siteTitle}（{siteDesc}）</title>
        <meta name="description" content={siteDesc} />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${siteTitle}|${siteDesc}`} />
        <meta property="og:description" content={siteDesc} />
        <meta property="og:image" content={`${siteUrl}/logo.png`} />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:width" content="1200" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={`${siteUrl}/logo.png`} />
        <meta name="twitter:title" content={`${siteTitle}|${siteDesc}`} />
        <meta name="twitter:description" content={siteDesc} />
      </Helmet>
      <TopLayout data={props.data}>
        <PostCardGroup data={props.data.posts} />
        <IssueCardTemplate data={props.data.issues} />
      </TopLayout>
    </>
  )
}

export const query = graphql`
  query Index {
    site {
      siteMetadata {
        title
        siteUrl
        description
        headerLinks {
          label
          url
        }
      }
    }
    posts : allMdx(
      limit: 12,
      sort: { order: DESC, fields: frontmatter___date }, 
      filter: { 
        frontmatter: { 
          publish: { eq: true }
          posttype: { ne: "issue" }
        } 
      }
    ) {
      nodes {
        id
        frontmatter {
          date
          title
          slug
          category
          posttype
          thumbnail {
            childImageSharp {
              gatsbyImageData
            }
          }
          tags
        }
        parent {
          ... on File {
            name
          }
        }
      }
    }
    issues : allMdx(
      limit: 10,
      sort: { order: DESC, fields: frontmatter___date }, 
      filter: { 
        frontmatter: { 
          publish: { eq: true }
          posttype: { eq: "issue" }
        } 
      }
    ) {
      nodes {
        id
        frontmatter {
          date
          title
          slug
          icon {
            childImageSharp {
              gatsbyImageData
            }
          }
          tags
          open
        }
        parent {
          ... on File {
            name
          }
        }
      }
    }
  }
`;


export default IndexPage
