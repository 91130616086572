import React, { ReactNode } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../views/Header';
import Footer from '../views/Footer';
import PageTopButton from '../parts/PageTopButton';
import { IndexQuery } from '../../../graphql-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  @media screen and (max-width: 480px) {
    overflow: hidden;
  }
`;

type Props = {
  data: IndexQuery
  children?: ReactNode
}

const TopLayout = (props: Props) => {
  const { data,children } = props;
  return (
    <Wrapper>
      <Container fluid className="px-0 main">
        <Row className="justify-content-center">
          <Col>
            <Header data={data}/>
          </Col>
        </Row>
      </Container>
      {/* <Container fluid className="px-0 main">
        <Row className="justify-content-center">
          <Col>
            <TopCarousel />
          </Col>
        </Row>
      </Container> */}
      <Container className="mt-4 mb-4">
        <Row>
          <Col lg={{ span: 12, offset: 0 }} md={{ span: 12, offset: 0 }} sm={12} xs={12}>
            <main>{children}</main>
          </Col>
        </Row>
      </Container>
      <Container fluid className="px-0">
        <Row>
          <Col className="footer-col" sm={12} xs={12}>
            <Footer />
          </Col>
        </Row>
        <PageTopButton />
      </Container>
    </Wrapper>
  );
}

export default TopLayout;