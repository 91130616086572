import React from 'react';
import { Link } from 'gatsby';
import { Row, Col, Card } from 'react-bootstrap';
import { StaticImage, GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { FaRegCalendarAlt,FaFolder,FaTag } from 'react-icons/fa'
import moment from "moment"
import { kebabCase } from 'lodash/string';

const Wrapper = styled.div`
  .card {
    border-radius: 0;
    border: none;
  }
  .card-body {
    padding: 0.5rem 0;
    background-color: #fff;
  }
  .card-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    text-align: left;
    color: black;
    text-decoration: none;
  }
  .gatsby-image-wrapper{
    height: 170px !important;
    /* border: 1px solid #ccc; */
    border-radius: 10px;
  }
  .date,.category,.tag{
    font-size:11px;
    color:gray;
  }
  .category:before{
    content: " ";
  }
  .card-body a{
    text-decoration: none;
  }
  .post-meta{
    line-height: 100%;
    margin-top: 2px;
    color:gray;
  }
  .post-meta a{
    color:gray;
  }
`;
const GatsbyImageStyle = styled(GatsbyImage)`
width: 100%;
height: 10vw;
`;
const staticImageStyle = { width: `100%`, height: `15vw` };
const CardStyle = styled(Card)`
border: none;
`;
export default function PostCardGroup(props: any) {
  const { data } = props;
  return (
    <Wrapper>
      <Row>
      {data.nodes.map((node: any) => (
        <Col lg={3} md={4} sm={6} xs={12} key={node.id} className="d-flex align-items-stretch mb-4">
          <CardStyle key={node.id}>
            { node.frontmatter.posttype === 'issue' ? (
              <Link to={`/issue/${node.parent.name}`}>
                {getImage(node.frontmatter.thumbnail) ? 
                ( <GatsbyImageStyle image={getImage(node.frontmatter.thumbnail) as IGatsbyImageData} alt={node.frontmatter.title} /> ) 
                : 
                ( <StaticImage style={staticImageStyle} alt="no image" src="../../../images/no-image.png" /> )
              }
              </Link>
            ) : (
              <Link to={`/${node.frontmatter.slug}`}>
                {getImage(node.frontmatter.thumbnail) ? 
                ( <GatsbyImageStyle image={getImage(node.frontmatter.thumbnail) as IGatsbyImageData} alt={node.frontmatter.title} /> ) 
                : 
                ( <StaticImage style={staticImageStyle} alt="no image" src="../../../images/no-image.png" /> )
              }
              </Link>
            )}
            <Card.Body>
            { node.frontmatter.posttype === 'issue' ? (
              <Link to={`/issue/${node.parent.name}`}>
                <Card.Title>[問題解決]{node.frontmatter.title}</Card.Title>
              </Link>
              ) : (
              <Link to={`/${node.frontmatter.slug}`}>
                <Card.Title>{node.frontmatter.title}</Card.Title>
              </Link>
            )}
              <div className='post-meta'>
                <div className='date'><FaRegCalendarAlt /> {moment(node.frontmatter.date).format(`YYYY年M月D日 H:mm`)} </div>
                { node.frontmatter.posttype !== 'issue' && 
                <div className='category'>
                  <FaFolder />
                  <Link to={`/category/${kebabCase(node.frontmatter.category)}`}>
                    <span> { node.frontmatter.category } </span>
                  </Link>
                </div>
                }
                <div className='tag'>
                  <FaTag /> 
                  { node.frontmatter.tags.map((tag:string) => (
                    <Link  key={tag} to={`/tag/${kebabCase(tag)}`}><span> { tag } </span></Link>
                  ))}
                </div>
              </div>
            </Card.Body>
          </CardStyle>
        </Col>
      ))}
      </Row>
    </Wrapper>
  );
}
          